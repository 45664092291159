import resolveFundraiser from "@dataResolvers/resolveFundraiser";
import queryCraftGQL from "../utils/queryCraftGQL";

const query = `
query getFundraiser($slug:[String]) {
  entry(slug:$slug, site:"*",after:"2022-11-20") {
    ... on fundraiser_fundraiser_Entry {
      id
      title
      goal
      fName
      lName
      heading0
      heading5
      expDate
      backgroundImage0 {
        id
        height
        url
        width
      }
    }
  }
}
`;

const getAction = async slug => {
  const variables = { slug };
  const { entry } = await queryCraftGQL({ query, variables });
  if (entry) {
    return resolveFundraiser(entry);
  }
  return false;
};

export default getAction;
